<template>
  <div class="ios">
    <!-- <Editor :init="init" v-model="form.content"></Editor> -->
    <img src="@/assets/index/ios.png" />
  </div>
</template>

<script>
// import Editor from "@tinymce/tinymce-vue";
export default {
  name:'DownIOS',
  // components: { Editor },
  data() {
    return {
      /* form:{
        content:''
      },
      init: {
        language_url: "./langs/zh-Hans.js",
        language: "zh_CN",
        height: 430,
        plugins:"link lists image code table colorpicker textcolor wordcount contextmenu",
        toolbar:"bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify|bullist numlist |outdent indent blockquote | undo redo | link unlink image code | removeformat",
        branding: false,
        images_upload_url: '/api/upload/saveimgPathLocation',
        // images_upload_base_path: 'http://10.73.100.165:8081',
        images_upload_base_path: 'http://10.73.20.62:80',
      }, */
    }
  },
}
</script>

<style>
  .ios{
    padding: 5%;
    text-align: center;
  }
</style>
